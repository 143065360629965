<i18n>
{
  "en": {
    "copy": "Copy",
    "copied": "Copied"
  },
  "ja": {
    "copy": "コピー",
    "copied": "コピーしました"
  }
}
</i18n>

<template>
  <v-tooltip bottom transition="fade-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" @click="copy" @blur="reset" @mouseleave="reset">mdi-content-copy</v-icon>
    </template>
    <span v-if="copied">{{ $t('copied') }}</span>
    <span v-else>{{ $t('copy') }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'CopyButton',
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data: () => ({
    copied: false
  }),
  methods: {
    async copy() {
      await navigator.clipboard.writeText(this.text);
      this.copied = true;
    },
    reset() {
      setTimeout(() => this.copied = false, 200);
    }
  }
}
</script>
